



























































































































































































































































































































































































































































































































































.grantListInfo {
  .framePage {
    .framePage-body {
      padding: 60px;
      .el-form {
        width: 700px;
        .ccardNumber {
          width: 300px;
        }
        .ctermValidity {
          width: 500px;
        }
        .cz {
          text-align: center;
          margin-top: 20%;
          .el-button {
            padding: 9px 25px;
          }
        }
      }
    }
  }
  .dialog1 {
    .p1 {
      line-height: 30px;
      padding-left: 20px;
      .s2 {
        color: #909399;
      }
    }
    .p2 {
      color: #f56c6c;
      margin-top: 20px;
      padding: 0 20px;
    }
  }
}
.grantListInfo
  .framePage
  .framePage-body
  .el-form
  .ccurriculum
  /deep/
  .el-form-item__label {
  width: 70px !important;
}
.grantListInfo
  .framePage
  .framePage-body
  .el-form
  .ccurriculum
  /deep/
  .el-form-item__content {
  margin-left: 70px !important;
}
