.grantListInfo .framePage .framePage-body {
  padding: 60px;
}
.grantListInfo .framePage .framePage-body .el-form {
  width: 700px;
}
.grantListInfo .framePage .framePage-body .el-form .ccardNumber {
  width: 300px;
}
.grantListInfo .framePage .framePage-body .el-form .ctermValidity {
  width: 500px;
}
.grantListInfo .framePage .framePage-body .el-form .cz {
  text-align: center;
  margin-top: 20%;
}
.grantListInfo .framePage .framePage-body .el-form .cz .el-button {
  padding: 9px 25px;
}
.grantListInfo .dialog1 .p1 {
  line-height: 30px;
  padding-left: 20px;
}
.grantListInfo .dialog1 .p1 .s2 {
  color: #909399;
}
.grantListInfo .dialog1 .p2 {
  color: #f56c6c;
  margin-top: 20px;
  padding: 0 20px;
}
.grantListInfo .framePage .framePage-body .el-form .ccurriculum /deep/ .el-form-item__label {
  width: 70px !important;
}
.grantListInfo .framePage .framePage-body .el-form .ccurriculum /deep/ .el-form-item__content {
  margin-left: 70px !important;
}
